import axios from 'axios';
import _ from 'lodash';
import { Notice } from './model';

const prdUrl = 'https://swallow-dental.netlify.app/.netlify/functions/notice';
const localUrl = 'http://localhost:8888/.netlify/functions/notice';
const targetUrl = window.location.hostname === 'localhost' ? localUrl : prdUrl;

export class ApiService {

  static async getNoticeList(): Promise<Notice[]> {
    const response = await axios.get(targetUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  }

  static async getNoticeTopList(): Promise<Notice[]> {
    const response = await axios.get(targetUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return _.filter(response.data, (item) => {
      return item.is_top;
    });
  }

  static async getNoticeById(id: number): Promise<Notice> {
    const response = await axios.get(targetUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return _.find(response.data, { id }) || null;
  }
}