import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { mainTheme } from "@/themes";

type RootLayoutProps = {
  children: React.ReactNode;
};

export const RootLayout: React.FC<RootLayoutProps> = ({ children }) => (
  <>
    <HelmetProvider>
      <Helmet>
        <title>aaaa</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Helmet>
      <CssBaseline />
      <ThemeProvider theme={mainTheme}>
        {children}
      </ThemeProvider>
    </HelmetProvider>
  </>
);
