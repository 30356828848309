import { NoticeList } from "@/components/NoticeList"
import { Container } from "@mui/material"
import { useEffect, useState } from "react"
import { Notice } from '@/service/model';
import { ApiService } from "@/service/api";
import { MainTitle } from "@/components/ui/MainTitle";

function NoticeIndex() {
  const [noticeList, setNotiseList] = useState<Notice[]>([])
  useEffect(() => {
    ApiService.getNoticeList().then((list) => {
      setNotiseList(list);
    }).catch(() => {

    });
  }, []);

  return <>
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <MainTitle title="お知らせ" />

      <NoticeList
        list={noticeList}
      />
    </Container>
  </>
}

export default NoticeIndex
