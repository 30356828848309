import { Notice } from "@/service/model"
import { Box, Stack, Typography } from "@mui/material"
import dayjs from "dayjs"
import { Link } from "react-router-dom"

type Props = {
  list: Notice[],
}

export const NoticeList = (props: Props) => {
  return (
    <Stack
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      padding={2}
    >
      {props.list.map((notice) => (
        <Box
          padding={0}
          width="100%"
          display="flex"
          justifyContent="start"
          alignItems="stretch"
          gap={0}
        >
          <Box
            padding={1}
            sx={{
              width: '151px',
              minWidth: '151px',
              textAlign: 'center',
              backgroundColor: '#faf7f1',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: '#333333',
                lineHeight: 1,
              }}
            >
              {dayjs(notice.publish_date).format('YYYY.MM.DD')}
            </Typography>
          </Box>
          <Box
            padding={1}
            sx={{
              width: '100%',
            }}
          >
            <Link
              to={`/notice/${notice.id}`}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  lineHeight: 1.2,
                  '&:hover': {
                    textDecoration: 'underline',
                    color: '#06c',
                  }
                }}
              >
                {notice.title}
              </Typography>
            </Link>
          </Box>
        </Box>
      ))}
    </Stack>
  )
}