import { Typography } from "@mui/material"

type Props = {
  title: string
}

export const Title = (props: Props) => {
  return (
    <Typography
      variant="h2"
      textAlign="center"
      sx={{
        color: 'rgba(0,0,0,0.67)',
        fontWeight: 700,
        width: 'fit-content',
        margin: '24px auto',
        padding: 2,
        fontSize: {
          xs: '28px',
          md: '36px',
        }
      }}
    >
      {props.title}
    </Typography>
  )
}