import { Stack, Typography } from "@mui/material"


const tdStyle = {
  width: '50%',
  lineHeight: '40px',
  display: 'inline-block',
  marginLeft: '-1px',
  marginTop: '-1px',
  paddingLeft: '8px',
};

const thStyle = {
  backgroundColor: '#e3e3e3',
}

const borderStyle = {
  border: '1px solid rgba(231, 231, 231)',
}

type Props = {
  title: string;
  sub_list: {
    title?: string;
    list: {
      title: string,
      price: number | string,
    }[]
  }[]
  description?: string | JSX.Element;
}
export const PriceTable = (props: Props) => {

  return (
    <Stack
      display="flex"
      alignItems="start"
      justifyContent="start"
      gap={2}
      padding={2}
      width="100%"
    >
      <Typography
        variant="subtitle1"
        sx={{
          color: '#777',
          fontSize: '20px',
          fontWeight: 700,
          borderLeft: 'solid 4px #CCC',
          paddingLeft: '8px'
        }}
      >
        {props.title}
      </Typography>

      {props.sub_list.map(sub => (
        <Stack
          width="100%"
          display="flex"
          gap={1}
          alignItems="start"
          justifyContent="start"
        >
          {sub.title &&
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '16px',
                fontWeight: 600,
              }}
            >
              {sub.title}
            </Typography>
          }
          <Typography
            variant="subtitle2"
            sx={{
              color: '#757575',
              fontSize: {
                xs: '12px',
                md: '14px',
              },
              width: '100%'
            }}
            component="div"
          >
            <table
              style={{
                borderCollapse: 'collapse',
                display: 'block',
                width: '100%',
              }}
            >

              <tbody style={{ display: 'block', width: '100%' }}>
                <tr style={{ display: 'block', width: '100%' }}>
                  <th style={{ ...tdStyle, ...borderStyle, ...thStyle }}>項目</th>
                  <th style={{ ...tdStyle, ...borderStyle, ...thStyle }}>費用（税込）</th>
                </tr>
                {sub.list.map((item) => (
                  <tr key={item.title} style={{ display: 'block', width: '100%' }}>
                    <td style={{ ...tdStyle, ...borderStyle }}>
                      {item.title}
                    </td>
                    <td style={{ ...tdStyle, ...borderStyle, textAlign: 'center', }}>
                      {item.price ? typeof item.price === 'string' ? item.price : `${Number(item.price).toLocaleString()}円` : '未定'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Typography>
        </Stack>
      ))}

      {props.description && (
        <Typography
          variant="body1"
        >
          {props.description}
        </Typography>
      )}
    </Stack >
  )
}