import HomeIndex from "./pages/Home";
import PriceIndex from "./pages/Price";
import NoticeIndex from "./pages/Notice";
import NoticeView from "./pages/NoticeView";

type routeType = {
  path: string;
  component: (React.FC) | (() => JSX.Element);
  title?: string;
};


export const routes: routeType[] = [
  {
    path: "/",
    component: HomeIndex,
    // title: 'Home',
  },
  {
    path: "/price",
    component: PriceIndex,
    title: '料金表',
  },
  {
    path: "/notice",
    component: NoticeIndex,
    title: 'お知らせ',
  },
  {
    path: "/notice/:id",
    component: NoticeView,
    title: 'お知らせ',
  },
];