import { Box, Stack, Typography } from "@mui/material";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import { Title } from "@/components/ui/Title";

export const Access = () => {
  return (
    <Stack
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={3}
      paddingX={2}
      component="section"
      id="access"
    >
      <Title
        title="Access"
      />

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10528.512800949748!2d139.75647043826825!3d35.695822057103996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d384f0a2d3d%3A0x4d61a933f62cbc76!2z5b6h6Iy244OO5rC044Gk44Gw44KB5q2v56eR44O755-v5q2j5q2v56eR!5e0!3m2!1sja!2sjp!4v1718207086676!5m2!1sja!2sjp"
        height="150"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        style={{
          display: 'block',
          border: 0,
          width: "360px",
          maxWidth: "100%"
        }}
      />

      <Stack
        display="flex"
        gap={3}
        alignItems="start"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: '#616161',
              borderRadius: '50%',
              width: '50px',
              minWidth: '50px',
              height: '50px',
            }}
          >
            <FmdGoodIcon
              sx={{
                color: '#FFFFFF',
                lineHeight: 1,
                fontSize: '20px',
              }}
            />
          </Box>

          <Typography
            variant="body1"
            style={{
              fontSize: '16px',
              lineHeight: 1.5,
            }}
          >
            〒101-0052<br />
            東京都千代田区神田小川町3丁目8-10<br />
            メアリヒト御茶ノ水ビル1階
          </Typography>

        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: '#616161',
              borderRadius: '50%',
              width: '50px',
              minWidth: '50px',
              height: '50px',
            }}
          >
            <DirectionsTransitIcon
              sx={{
                color: '#FFFFFF',
                lineHeight: 1,
                fontSize: '20px',
              }}
            />
          </Box>

          <Stack>
            <Typography
              variant="body1"
              style={{
                fontSize: '16px',
                lineHeight: 1.5,
              }}
            >
              ・JR御茶ノ水駅 御茶ノ水橋口改札 徒歩6分<br />
              ・千代田線 新御茶ノ水駅B3b 徒歩5分<br />
              ・神保町駅(半蔵門線、都営新宿線、都営三田線)A5 徒歩6分<br />
              ・都営新宿線 小川町駅、丸の内線 淡路町駅B5 徒歩6分<br />
              ・東西線 竹橋駅 3a 徒歩10分
            </Typography>
          </Stack>
        </Box>
      </Stack>

    </Stack >
  )
};