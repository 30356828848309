import { Box, Card, Stack, Typography } from "@mui/material"

const tdStyle = {
  width: 'calc(100% / 7)',
  lineHeight: '48px',
  display: 'inline-block',
  marginLeft: '-1px',
  marginTop: '-1px',
};

const td2Style = {
  width: 'calc(100% / 7)',
  height: '64px',
  display: 'inline-block',
  marginLeft: '-1px',
  marginTop: '-1px',
};

const borderStyle = {
  border: '1px solid rgba(231, 231, 231)',
}

type Props = {
  id?: string
};
export const Calendar = (props: Props) => {
  return (
    <Card
      sx={{
        margin: "0 auto",
        padding: "16px 8px",
        textAlign: "center",
        width: {
          xs: '100%',
          md: "750px",
        },
        border: "solid 2px rgb(150, 155, 178)",
        borderRadius: '16px',
      }}
      id={props.id}
      component="section"
    >
      <Stack
        display="flex"
        gap={2}
      >
        <Typography variant="subtitle1">
          診療時間
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: '#757575',
            fontSize: {
              xs: '12px',
              md: '14px',
            }
          }}
        >
          <Box
            sx={{
              width: '100%'
            }}
          >
            <table
              style={{
                borderCollapse: 'collapse',
                display: 'block',
                width: '100%',
              }}
            >
              <tbody style={{ display: 'block', width: '100%' }}>
                <tr style={{ display: 'block', width: '100%' }}>
                  <td style={{ ...tdStyle, ...borderStyle }}>月</td>
                  <td style={{ ...tdStyle, ...borderStyle }}>火</td>
                  <td style={{ ...tdStyle, ...borderStyle }}>水</td>
                  <td style={{ ...tdStyle, ...borderStyle }}>木</td>
                  <td style={{ ...tdStyle, ...borderStyle }}>金</td>
                  <td style={{ ...tdStyle, ...borderStyle }}>土</td>
                  <td style={{ ...tdStyle, ...borderStyle }}>日・祝</td>
                </tr>

                <tr style={{ display: 'block', width: '100%' }}>
                  <td style={{ ...td2Style, ...borderStyle }}><br />ー<br /></td>
                  <td style={{ ...td2Style, ...borderStyle }}>12:00<br />〜<br />20:00</td>
                  <td style={{ ...td2Style, ...borderStyle }}>8:00<br />〜<br />17:00</td>
                  <td style={{ ...td2Style, ...borderStyle }}>8:00<br />〜<br />17:00</td>
                  <td style={{ ...td2Style, ...borderStyle }}>12:00<br />〜<br />20:00</td>
                  <td style={{ ...td2Style, ...borderStyle }}>10:00<br />〜<br />15:00</td>
                  <td style={{ ...td2Style, ...borderStyle }}><br />ー<br /></td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Typography >

        <Typography
          variant="subtitle2"
          textAlign="right"
        >
          ※初診最終受付は診療時間終了1時間前
        </Typography>
      </Stack>
    </Card>
  )
}