import React from 'react'
import ReactDOM from 'react-dom/client'
import { RootLayout } from "@/layouts/RootLayout";
import { BaseLayout } from "@/layouts/BaseLayout";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme } from "@/themes";
import './global.css';

import { BrowserRouter } from 'react-router-dom';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <RootLayout>
        <ThemeProvider theme={mainTheme}>
          <BaseLayout />
        </ThemeProvider>
      </RootLayout>
    </BrowserRouter>
  </React.StrictMode>
)
