import { Container, Divider, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Notice } from '@/service/model';
import { ApiService } from "@/service/api";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

function NoticeView() {
  const { id } = useParams();
  const [notice, setNotice] = useState<Notice | null>(null)
  useEffect(() => {
    ApiService.getNoticeById(Number(id)).then((data) => {
      setNotice(data);
    }).catch(() => {

    });
  }, []);

  if (!notice) {
    return null;
  }

  return <>
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <Stack
        padding={2}
        sx={{
          width: '100%',
          maxWidth: '640px',
          margin: '0 auto',
        }}
        display="flex"
        gap={3}
      >
        <Typography
          variant="subtitle1"
          component="h2"
          sx={{
            color: '#616161',
            alignItems: 'left',
            fontSize: '28px',
            fontWeight: 500,
          }}
        >
          {notice.title}
        </Typography>

        <Typography
          variant="subtitle1"
          component="h2"
          sx={{
            color: '#808080',
            alignItems: 'left',
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          {dayjs(notice.publish_date).format('YYYY.MM.DD')}
        </Typography>

        <Divider />

        <Typography
          variant="subtitle1"
          component="h2"
          sx={{
            color: 'rgba(0, 0, 0, 0.64)',
            alignItems: 'left',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: 1.5,
            whiteSpace: 'pre-line',
          }}
        >
          {notice.content}
        </Typography>
      </Stack>


    </Container>
  </>
}

export default NoticeView
