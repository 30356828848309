import { MainTitle } from "@/components/ui/MainTitle"
import { PriceTable } from "@/components/ui/PriceTable"
import { Container, Stack } from "@mui/material"

function Price() {
  return <>
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <MainTitle title="料金表" />
    </Container>

    <Stack
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={3}
    >
      <PriceTable
        title="保険診療"
        sub_list={[{
          list: [
            { title: '初診時', price: '¥3,300~5,500' },
            { title: '一般保険診療全般', price: '算定基準に基づく' },
          ]
        }]}
        description={(
          <>
            算定基準に基づき治療を行います。領収書に加え診療明細書を発行いたします。
          </>
        )}
      />

      <PriceTable
        title="歯のクリーニング(60分)"
        sub_list={[{
          list: [
            { title: '全体のクリーニング', price: 11000 },
            { title: '着色除去を追加', price: 5500 },
          ]
        }]}
        description={(
          <>
            歯の表面の汚れを落とし、専用の歯磨剤にて全体を磨きます。
          </>
        )}
      />

      <PriceTable
        title="ホワイトニング"
        sub_list={[{
          list: [
            { title: 'オフィスホワイトニング90分3回照射', price: 41800 },
            { title: 'ホームホワイトニング', price: 33000 },
            { title: 'ホームホワイトニング 薬剤のみ', price: 8800 },
            { title: 'デュアルホワイトニング', price: 60500 },
            { title: 'オフィスホワイトニング3回分セット割引', price: 115500 },
          ]
        }]}
      />

      <PriceTable
        title="部分矯正治療"
        sub_list={[{
          title: '検査、調整費用',
          list: [
            { title: '資料採得、治療相談', price: 2200 },
            { title: '精密検査', price: 22000 },
            { title: 'ワイヤー調整費用', price: '3300/月毎' },
            { title: 'マウスピース調整費用', price: '3300/2ヶ月毎' },
          ]
        }, {
          title: '3~3表側部分矯正',
          list: [
            { title: '上or下', price: 330000 },
            { title: '上下', price: 385000 },
          ]
        }, {
          title: '3~3裏側部分矯正＋マウスピース矯正',
          list: [
            { title: '上or下', price: 440000 },
            { title: '上下', price: 495000 },
          ]
        }, {
          title: '5~5表側部分矯正＋マウスピース矯正',
          list: [
            { title: '上or下', price: 385000 },
            { title: '上下', price: 440000 },
          ]
        }, {
          title: '5~5裏側部分矯正＋マウスピース矯正',
          list: [
            { title: '上or下', price: 550000 },
            { title: '上下', price: 605000 },
          ]
        }]}
        description={(
          <>
            ワイヤーや目立ちにくいマウスピースを用いて気になる部分のみを動かす矯正治療になります。噛み合わせによって不向きな場合もございますので先にご相談のみの時間を頂いております。
          </>
        )}
      />

      <PriceTable
        title="全体矯正治療"
        sub_list={[{
          title: '検査、調整費用',
          list: [
            { title: '資料採得、治療相談', price: 2200 },
            { title: '精密検査', price: 22000 },
            { title: 'ワイヤー調整費用', price: '3300/月毎' },
            { title: 'マウスピース調整費用', price: '3300/2ヶ月毎' },
          ]
        }, {
          list: [
            { title: '表側矯正＋マウスピース矯正', price: 935000 },
            { title: 'ハーフリンガル矯正＋マウスピース矯正', price: 990000 },
            { title: '裏側矯正＋マウスピース矯正', price: 1210000 },
            { title: '小臼歯抜歯1本', price: 11000 },
            { title: '8番抜歯垂直', price: 16500 },
            { title: '8番抜歯埋伏', price: 27500 },
            { title: 'インプラントアンカー1本', price: 16500 },
          ]
        }]}
        description={(
          <>
            ワイヤー、マウスピースをどちらも使用し、大臼歯部を含めて全体の歯を動かす必要がある方の矯正方法となります。綺麗に仕上げる為にマウスピースが先が良いか、ワイヤーが先が良いか。目立たない治療をご希望かどうかなどを相談の上、最適なプランをご提供いたします。
          </>
        )}
      />

      <PriceTable
        title="小児矯正治療"
        sub_list={[{
          title: '検査、調整費用',
          list: [
            { title: '資料採得、治療相談', price: 2200 },
            { title: '精密検査', price: 22000 },
            { title: '調整費用', price: '3300/月毎' },
          ]
        }, {
          list: [
            { title: '第一期治療(12歳頃まで)', price: 330000 },
          ]
        }, {
          list: [
            { title: '第二期治療から開始', price: '成人料金より15%OFF' },
          ]
        }]}
        description={(
          <>
            床矯正や筋機能矯正装置、ワイヤー矯正を用いて歯並びの改善を行います。
          </>
        )}
      />

      <PriceTable
        title="セラミック治療"
        sub_list={[{
          title: '3~3前歯部審美治療',
          list: [
            { title: 'ダイレクトボンディング(同時2箇所まで)', price: 44000 },
            { title: '1Dayセラミック仮歯', price: 22000 },
            { title: 'ラミネートベニア', price: 126500 },
            { title: 'ジルコニア マルチレイヤー', price: 137500 },
            { title: 'ジルコニア&セラミック', price: 176000 },
          ]
        }, {
          title: '4,5小臼歯部審美治療',
          list: [
            { title: 'ダイレクトボンディング(同時2箇所まで)', price: 33000 },
            { title: '1Dayセラミック小', price: 49500 },
            { title: 'ジルコニア小', price: 55000 },
            { title: '1Dayセラミック大', price: 93500 },
            { title: 'ラミネートベニア', price: 115000 },
            { title: 'ジルコニア マルチレイヤー', price: 110000 },
            { title: 'ジルコニア&セラミック', price: 165000 },
          ]
        }, {
          title: '6,7大臼歯部審美治療',
          list: [
            { title: 'ダイレクトボンディング(同時2箇所まで)', price: 36500 },
            { title: '1Dayセラミック小', price: 55000 },
            { title: 'ジルコニア小', price: 66000 },
            { title: '1Dayセラミック大', price: 96800 },
            { title: 'ジルコニア マルチレイヤー', price: 121000 },
          ]
        }]}
        description={(
          <>
            ・ セラミック治療料金にはコア築造代、仮歯代、型取り代、セメント代の治療費も含まれます。<br />
            ・ダイレクトボンディングは6ヶ月間の保証期間がございます。<br />
            ・ジルコニア、セラミック、ラミネートベニアは12ヶ月間の保証期間がございます。
          </>
        )}
      />

      <PriceTable
        title="スポーツマウスピース"
        sub_list={[{
          list: [
            { title: '学生割引1枚法', price: 16500 },
            { title: '一般の方1枚法', price: 22000 },
            { title: '学生割引2枚法', price: 35200 },
            { title: '一般の方2枚法', price: 44000 },
            { title: 'プロスポーツ選手向けセット', price: 132000 },
            { title: 'オリジナルステッカー追加', price: 3300 },
          ]
        }]}
      />

      <PriceTable
        title="ホワイトスポット改善"
        sub_list={[{
          list: [
            { title: 'アイコン', price: 38500 },
          ]
        }]}
        description={(
          <>
            薬剤を用いて歯を削らずにホワイトスポットの改善を行います。
          </>
        )}
      />



      <PriceTable
        title="歯髄保存療法"
        sub_list={[{
          list: [
            { title: 'MTA歯髄保存', price: 33000 },
          ]
        }]}
      />

      <PriceTable
        title="歯周組織再生療法"
        sub_list={[{
          list: [
            { title: '歯周組織再生療法', price: 110000 },
          ]
        }]}
      />

      <PriceTable
        title="歯肉移植"
        sub_list={[{
          list: [
            { title: '歯肉移植', price: 88000 },
          ]
        }]}
      />

      <PriceTable
        title="歯肉切除術"
        sub_list={[{
          list: [
            { title: '歯肉切除術', price: 77000 },
          ]
        }]}
        description={(
          <>
            ガミースマイルの改善向けに歯肉を切除します。
          </>
        )}
      />

      <PriceTable
        title="クラウンレングスニング"
        sub_list={[{
          list: [
            { title: 'クラウンレングスニング', price: 55000 },
          ]
        }]}
        description={(
          <>
            セラミックを入れる際により良い状態にする為に行う処置です。
          </>
        )}
      />

      <PriceTable
        title="エクストルージョン"
        sub_list={[{
          list: [
            { title: 'エクストルージョン', price: 88000 },
          ]
        }]}
        description={(
          <>
            歯根が短くてそのままでは被せ物が作れない場合に行います。
          </>
        )}
      />

      <PriceTable
        title="マイクロスコープ根幹治療"
        sub_list={[{
          title: '検査費用',
          list: [
            { title: '資料採得、治療相談', price: 2200 },
          ]
        }, {
          title: '抜髄や根管治療',
          list: [
            { title: '3~3前歯部', price: 55000 },
            { title: '4,5小臼歯部', price: 77000 },
            { title: '6,7大臼歯部', price: 88000 },
          ]
        }, {
          title: '歯根端切除術',
          list: [
            { title: '3~3前歯部', price: 77000 },
            { title: '4,5小臼歯部', price: 88000 },
            { title: '6,7大臼歯部', price: 110000 },
          ]
        }]}
      />

      <PriceTable
        title="インプラント治療"
        sub_list={[{
          list: [
            { title: '3~3前歯部', price: 550000 },
            { title: '4,5小臼歯部', price: 440000 },
            { title: '6,7大臼歯部', price: 495000 },
            { title: 'ソケットリフト', price: 88000 },
            { title: 'GBR', price: 88000 },
          ]
        }]}
        description={(
          <>
            CTと口腔内スキャナーを組み合わせてデジタルガイドを使用します。インプラント埋入から上部構造までを含む金額となります。
          </>
        )}
      />

      <PriceTable
        title="顎関節改善治療"
        sub_list={[{
          list: [
            { title: '資料採得、治療相談', price: 2200 },
            { title: '処置料', price: 71500 },
          ]
        }]}
        description={(
          <>
            口が真っ直ぐ開きにくい、関節に違和感がある方向けに改善を試みます。
          </>
        )}
      />

      <PriceTable
        title="歯のコスプレ"
        sub_list={[{
          title: 'はめこみ型マウスピース',
          list: [
            { title: '相談料', price: 0 },
            { title: '前歯を綺麗に揃えて見せる', price: 39500 },
            { title: '八重歯を作る', price: 49500 },
            { title: '特定のキャラクターに寄せる', price: 49500 },
          ]
        }, {
          title: 'ティースジュエリー(患者様持参物のみ対応)',
          list: [
            { title: 'デザイン相談、装着から除去まで', price: 33000 },
          ]
        }]}
        description={(
          <>
            コスプレイヤーの方向けや、イベントの際に使用頂ける歯の印象を変えるアイテムをオーダーメイドで製作いたします。
          </>
        )}
      />

      <PriceTable
        title="入れ歯"
        sub_list={[{
          list: [
            { title: '3歯までの部分入れ歯', price: 495000 },
            { title: '4歯以上の入れ歯', price: 715000 },
            { title: '総入れ歯', price: 880000 },
          ]
        }]}
        description={(
          <>
            目立ちにくいノンクラスプデンチャーと金属を使用したより噛みやすい入れ歯の２セットになります。
          </>
        )}
      />


    </Stack>
  </>
}

export default Price
