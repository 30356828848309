import { Box, Stack, SvgIcon, Typography } from "@mui/material";

type Props = {
  icon: typeof SvgIcon,
  title: string,
  text: string,
  alert?: string,
};

export const DigitalCard = (props: Props) => {
  return (
    <Box
      padding={2}
      display="flex"
      justifyContent="space-between"
      gap={1}
      alignItems="flex-start"
      sx={{
        width: {
          xs: '100%',
          sm: 'calc(50% - 8px)',
        }
      }}
    >
      <Box
        sx={{
          paddingTop: '2px',
        }}
      >
        <props.icon sx={{ fontSize: "32px", color: '#777', }} />
      </Box>
      <Stack
        width="100%"
        display="flex"
        gap={1}
        justifyContent="flex-start"
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: '#777',
            fontSize: '22px',
            fontWeight: 700,
            textDecoration: 'underline',
          }}
        >
          {props.title}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: 'rgba(0,0,0,0.67)',
            lineHeight: 1.5,
          }}
        >
          {props.text}
        </Typography>

        {props.alert && (
          <Typography
            variant="body1"
            sx={{
              color: 'rgba(0,0,0,0.67)',
              lineHeight: 1.5,
              fontSize: '10px',
            }}
          >
            {props.alert}
          </Typography>
        )}
      </Stack>
    </Box >
  )
}