import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { routes } from "@/routes";
import { Helmet } from "react-helmet-async";
import { Header } from "@/components/Header";
import { Stack } from "@mui/material";
import { Footer } from "@/components/Footer";
import { AddComponent } from "@/components/AddComponent";

export const BaseLayout: React.FC = () => {

  return (
    <>
      <Header />
      {/*<Container maxWidth="sm" style={{ padding: 0 }}></Container>*/}
      <Stack
        component="main"
        sx={{
          padding: '50px 0 0',
        }}
      >
        <Routes>
          {routes.map((route, idx) => {
            return (
              <Route
                key={idx}
                path={route.path}
                element={(
                  <>
                    <Helmet>
                      <title>{route.title ? `御茶ノ水つばめ歯科・矯正歯科 | ${route.title}` : '御茶ノ水つばめ歯科・矯正歯科│御茶ノ水、神保町の歯医者'}</title>
                    </Helmet>
                    <route.component />
                  </>
                )}
              />
            );
          })}
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </Routes>
      </Stack>
      <Footer />
      <AddComponent />
    </>
  );
};
